import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import { Container, Card } from "react-bootstrap";
import Image from "components/Image";
import "./ImageCard.scss";

const ImageCard = ({ className, imageFileName, imageAlt, header, subheader, preheader, ping, logoFileName, extraInfo }) => {
  let preheaderComponent = ""
  if (preheader) {
    preheaderComponent = <div className="intro-pre-in text-uppercase">{preheader}</div>
  }

  let subheaderComponent = ""
  if (subheader) {
    subheaderComponent = <div className="intro-sub-in text-uppercase">{subheader}</div>
  }

  let pingComponent = ""
  if (ping) {
    pingComponent = <div className="intro-ping"><a href={'mailto:' + ping} target="_blank" rel="noreferrer">{ping}</a></div>
  }

  let logoComponent = ""
  if (logoFileName) {
    logoComponent = <Image className="logo" fileName={logoFileName} alt={imageAlt || header || subheader} />
  }

  return (
    <Card className={clsx("image-card bg-dark text-white text-center", className)}>
      <Image className="image" fileName={imageFileName} alt={imageAlt || header || subheader} />
      <Card.ImgOverlay className="no-padding">
        <Container>
          <div className="intro-text">
            {logoComponent}
            {preheaderComponent}
            <div className="intro-heading text-uppercase">{header}</div>
            {subheaderComponent}
            {extraInfo}
            {pingComponent}
          </div>
        </Container>
      </Card.ImgOverlay>
    </Card>
  );
};

ImageCard.propTypes = {
  className: PropTypes.string,
  imageFileName: PropTypes.string,
  imageAlt: PropTypes.string,
  header: PropTypes.string,
  subheader: PropTypes.string,
  preheader: PropTypes.string,
  ping: PropTypes.string,
  logoFileName: PropTypes.string,
  extraInfo: PropTypes.any,
};

ImageCard.defaultProps = {
  className: null,
  imageFileName: null,
  imageAlt: null,
  header: "",
  subheader: "",
  preheader: "",
  ping: "",
  logoFileName: "",
  extraInfo: null,
};

export default ImageCard;
